import {
  Coupon,
  CouponType,
  OrderItem,
} from "@efarmz/efarmz-domain-typescript";

const getCouponAmount = (
  coupon: Coupon,
  subTotal: number,
  total: number,
  items: OrderItem[]
) => {
  if (coupon.minOrder === undefined || coupon.minOrder <= subTotal) {
    if (coupon.type === CouponType.AMOUNT) {
      return coupon.amountOff || 0;
    } else if (coupon.type === CouponType.PERCENT) {
      return total * ((coupon.percentOff || 0) / 100);
    } else if (coupon.type === CouponType.PRODUCT && coupon.product?.id) {
      let total = 0;
      items.forEach((item) => {
        if (item.item?.id!.includes(coupon.product?.id as string)) {
          total =
            total +
            (item.item.finalPrice ? item.item.finalPrice * (item.qty || 0) : 0);
        }
      });
      return total;
    } else if (coupon.type === CouponType.PRODUCT_AMOUNT) {
      let total = 0;
      items.forEach((item) => {
        if (item?.item?.id?.includes(coupon.product?.id as string)) {
          total = total + (coupon.amountOff || 0) * (item.qty || 0);
        }
      });
      return total;
    } else if (coupon.type === CouponType.PRODUCT_DISCOUNT) {
      let total = 0;
      items.forEach((item) => {
        if (item?.item?.id?.includes(coupon.product?.id as string)) {
          total = total + ((coupon.percentOff || 0) / 100) * (item.qty || 0);
        }
      });
      return total;
    }
  }

  return 0;
};

export default getCouponAmount;
