import { useCallback } from "react";
import { toast, ToastContentProps, ToastOptions } from "react-toastify";

import {
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";

type NotificationProps = {
  title: string;
  subtitle?: string;
  type?: string;
};

const Success = ({
  title,
  subtitle,
  type = `success`,
  closeToast,
}: ToastContentProps & NotificationProps) => {
  return (
    <div className="flex items-start">
      <div className="flex-shrink-0">
        {type === `success` ? (
          <CheckCircleIcon
            className={twMerge(`h-6 w-6`, `text-green-400`)}
            aria-hidden="true"
          />
        ) : (
          <XCircleIcon
            className={twMerge(`h-6 w-6`, `text-red-700`)}
            aria-hidden="true"
          />
        )}
      </div>
      <div className="ml-3 w-0 flex-1 pt-0.5">
        <p className="text-sm font-medium text-gray-900">{title}</p>
        {subtitle && <p className="mt-1 text-sm text-gray-500">{subtitle}</p>}
      </div>
      <div className="ml-4 flex flex-shrink-0">
        <button
          type="button"
          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-vert focus:ring-offset-2"
          onClick={closeToast}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

type useNotificationsOut = {
  success: (title: string, subtitle?: string, options?: ToastOptions) => void;
  error: (title: string, subtitle?: string, options?: ToastOptions) => void;
};

const useNotifications = (): useNotificationsOut => {
  const success = useCallback(
    (title: string, subtitle?: string, options?: ToastOptions) => {
      toast(
        (props: ToastContentProps) => (
          <Success
            title={title}
            subtitle={subtitle}
            {...props}
            type="success"
          />
        ),
        options
      );
    },
    []
  );
  const error = useCallback(
    (title: string, subtitle?: string, options?: ToastOptions) => {
      toast(
        (props: ToastContentProps) => (
          <Success title={title} subtitle={subtitle} {...props} type="error" />
        ),
        options
      );
    },
    []
  );
  return {
    success,
    error,
  };
};

export default useNotifications;
