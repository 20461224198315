import {
  config,
  OrderableItemTag,
  Product,
} from "@efarmz/efarmz-domain-typescript";

const useProductHelper = (
  product: Product
): {
  shouldShowDiscountRate: boolean;
  isOrderable: boolean;
} => {
  const shouldShowDiscountRate = product?.tags
    ? product?.tags?.includes(OrderableItemTag.PROMO) ||
      product?.tags?.includes(OrderableItemTag.QUICKSALE) ||
      product?.tags?.includes(OrderableItemTag.FLASH_HENRY) ||
      product?.tags?.includes(OrderableItemTag.ZERO_WASTE)
    : false;

  const isOrderable = !!(
    product?.categories &&
    product?.categories?.length > 0 &&
    config.menuRootCategoryIds.includes(product?.categories[0]?.id!) &&
    product?.isOnline
  );

  return {
    shouldShowDiscountRate,
    isOrderable,
  };
};

export default useProductHelper;
