"use client";

import { useEffect, useMemo, useState } from "react";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { LuCalendarClock } from "react-icons/lu";

import { format } from "date-fns";
import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import {
  OrderableItemType,
  OrderItem,
  OrderItemStatus,
  OrderType,
  Product,
} from "@efarmz/efarmz-domain-typescript";
import Button from "@efarmz/efarmz-react-commons/esm/components/atoms/Button";

import useSubscription from "@/hooks/subscriptions/useSubscription";
import useUpdateSubscriptionItems from "@/hooks/subscriptions/useUpdateSubscriptionItems";
import useAuth from "@/hooks/useAuth";
import useCart from "@/hooks/useCart";
import useDeliveryWindow from "@/hooks/useDeliveryWindow";
import useProductHelper from "@/hooks/useProductHelper";

import NumericInput from "../../forms/NumericInput";

export type ProductActionButtonsProps = Omit<
  React.InputHTMLAttributes<HTMLDivElement>,
  `size`
> & {
  product: Product;
  className?: string;
  size?: `xs` | `sm` | `md` | `lg` | `xl`;
};

const ProductActionButtons = ({
  product,
  size = `xl`,
  ...other
}: ProductActionButtonsProps) => {
  const [isMount, setIsMount] = useState(false);

  const { user } = useAuth();

  const deliveryWindow = useDeliveryWindow();

  const subscription = useSubscription();
  const updateSubscriptionItems = useUpdateSubscriptionItems();

  const t = useTranslations();
  const productHelper = useProductHelper(product);

  useEffect(() => {
    setIsMount(true);
  }, []);

  let activeSubscriptionItem;
  if (subscription?.items?.length) {
    activeSubscriptionItem = subscription.items.find(
      (orderItem) => orderItem?.item?.id === product?.id
    );
  }

  const cart = useCart();

  const onshotItem = cart?.products?.items?.find(
    (item) =>
      item?.item?.id === product?.id && item?.orderType === OrderType.ONESHOT
  );
  const subscriptionItem: OrderItem | undefined = cart.products?.items?.find(
    (item) =>
      item?.item?.id === product?.id &&
      item?.orderType === OrderType.SUBSCRIPTION
  );

  const handleUpdateCart =
    (qty: number, orderType: OrderType) =>
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (orderType === OrderType.SUBSCRIPTION && user?.subscription) {
        updateSubscriptionItems([
          {
            item: product,
            qty,
          },
        ]);
      } else {
        cart.addItems([{ item: product, qty }], orderType);
      }
    };

  const handleUpdateActiveSubscriptionItem =
    (qty: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (subscription) {
        updateSubscriptionItems([
          {
            item: product,
            qty,
          },
        ]);
      }
    };

  const currentStock =
    product?.stock && deliveryWindow.value.date
      ? product?.stock[format(deliveryWindow.value.date, `yyyyMMdd`)]
      : 0;

  const orderItemStatus = useMemo(() => {
    let orderItemStatus = OrderItemStatus.AVAILABLE;

    if (!product.isOnline) {
      return OrderItemStatus.OFFLINE;
    }

    if (!productHelper.isOrderable) {
      return OrderItemStatus.UNAVAILABLE;
    }

    const hasSomeStock = product?.stock
      ? Object.keys(product!.stock).some((key) => {
          return product!.stock![key] > 0;
        })
      : false;
    const dayKey = deliveryWindow?.value?.date
      ? format(deliveryWindow.value.date, `yyyyMMdd`)
      : null;

    if (dayKey && product?.offDays?.includes(dayKey)) {
      orderItemStatus = OrderItemStatus.BRAND_ON_VACATION;
    } else if (
      dayKey &&
      product?.type === OrderableItemType.PRODUCT &&
      product?.stock &&
      product?.stock[dayKey] < 1
    ) {
      if (hasSomeStock) {
        orderItemStatus = OrderItemStatus.UNAVAILABLE;
      } else {
        orderItemStatus = OrderItemStatus.OUT_OF_STOCK;
      }
    }
    return orderItemStatus;
  }, [deliveryWindow.value.date, product, productHelper.isOrderable]);

  return isMount ? (
    <div {...other} suppressHydrationWarning>
      {orderItemStatus !== OrderItemStatus.AVAILABLE ? (
        <p className="text-sm font-medium text-menthe-500">
          {t(`dataset.OrderItemStatus.${orderItemStatus}`)}
        </p>
      ) : (
        <div className="space-y-1">
          {product?.isAvailableForSubscription && (
            <>
              {activeSubscriptionItem ? (
                <NumericInput
                  value={activeSubscriptionItem?.qty}
                  size={size}
                  color="secondary"
                  className="w-full"
                  Icon={LuCalendarClock}
                  disabled={!productHelper.isOrderable}
                  onClickAdd={handleUpdateActiveSubscriptionItem(1)}
                  onClickRemove={handleUpdateActiveSubscriptionItem(-1)}
                />
              ) : (
                <>
                  {subscriptionItem ? (
                    <NumericInput
                      value={subscriptionItem?.qty}
                      size={size}
                      color="secondary"
                      className="w-full"
                      Icon={LuCalendarClock}
                      disabled={!productHelper.isOrderable}
                      onClickAdd={handleUpdateCart(1, OrderType.SUBSCRIPTION)}
                      onClickRemove={handleUpdateCart(
                        -1,
                        OrderType.SUBSCRIPTION
                      )}
                    />
                  ) : (
                    <Button
                      color="secondary"
                      variant="contained"
                      size={size}
                      disabled={!!onshotItem || !productHelper.isOrderable}
                      className={twMerge(
                        `w-full`,
                        !product?.isAvailableForSubscription && `hidden`
                      )}
                      startIcon={<LuCalendarClock className="h-4 w-4" />}
                      onClick={
                        subscription
                          ? handleUpdateActiveSubscriptionItem(1)
                          : handleUpdateCart(1, OrderType.SUBSCRIPTION)
                      }
                    >
                      <span className="truncate">{t(`common.subscribe`)}</span>
                    </Button>
                  )}
                </>
              )}
            </>
          )}
          {onshotItem ? (
            <NumericInput
              value={onshotItem?.qty}
              variant="contained"
              color="primary"
              size={size}
              className="w-full"
              Icon={HiOutlineShoppingBag}
              disabled={!productHelper.isOrderable}
              onClickAdd={
                currentStock - (onshotItem?.qty || 0) >= 1
                  ? handleUpdateCart(1, OrderType.ONESHOT)
                  : () => {}
              }
              onClickRemove={handleUpdateCart(-1, OrderType.ONESHOT)}
            />
          ) : (
            <Button
              color="primary"
              variant="contained"
              size={size}
              className="w-full"
              disabled={!!subscriptionItem || !productHelper.isOrderable}
              startIcon={
                <HiOutlineShoppingBag className="text-menthe-100 h-4 w-4" />
              }
              onClick={handleUpdateCart(1, OrderType.ONESHOT)}
            >
              {t(`common.buy`)}
            </Button>
          )}
        </div>
      )}
    </div>
  ) : null;
};

export default ProductActionButtons;
