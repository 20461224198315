import {
  config,
  Coupon,
  DeliveryType,
  OrderType,
} from "@efarmz/efarmz-domain-typescript";

const getDeliveryFee = (
  subTotal: number,
  orderType: OrderType,
  countryIso2: string,
  deliveryType: DeliveryType,
  coupons: Coupon[]
) => {
  if (deliveryType === DeliveryType.DROP_OFF_POINT) {
    return 0;
  } else if (subTotal >= config.freeDeliveryFeeFrom) {
    return 0;
  } else if (
    coupons.some(
      (coupon) =>
        coupon.campaign &&
        config.couponCampaignsFreeDelivery.includes(coupon.campaign)
    )
  ) {
    return 0;
  } else {
    if (orderType === OrderType.SUBSCRIPTION) {
      return config.deliveryFeeAbo;
    } else {
      if (countryIso2 === `LU`) {
        return config.deliveryFeeLu;
      } else {
        return config.deliveryFeeBe;
      }
    }
  }
};

export default getDeliveryFee;
