import { useMemo } from "react";

import { isAfter, isBefore } from "date-fns";
import { useLocale } from "next-intl";
import { useRecoilState } from "recoil";

import {
  Box,
  Cart,
  Coupon,
  Meal,
  OrderableItemTag,
  OrderableItemType,
  OrderItem,
  OrderItemStatus,
  OrderType,
  Refund,
} from "@efarmz/efarmz-domain-typescript";

import useDeliveryWindow from "@/hooks/useDeliveryWindow";

import useAuth from "./useAuth";
import useMealBoxes from "./useMealBoxes";
import useNotifications from "./useNotifications";
import usePrevious from "./usePrevious";
import useSegment from "./useSegment";

import { CartAtom } from "@/state/atoms/CartAtom";
import getCouponAmount from "@/utils/getCouponAmount";
import getDeliveryFee from "@/utils/getDeliveryFee";
import getNextDeliveryDate from "@/utils/shared/getNextDeliveryDate";
import formatOrderableItem from "@/utils/shared/segment/formatOrderableItem";

const useCart = (): Cart & {
  addItems: (
    orderItems: OrderItem[],
    orderType: OrderType | `MEAL`,
    notify?: boolean
  ) => void;
  getOrderType: () => OrderType;
  addCoupon: (coupon: Coupon) => void;
  removeCoupon: (code: string) => void;
  addRefund: (refund: Refund) => void;
  removeRefund: (refund: Refund) => void;
  updateMealCartType: (orderType: OrderType) => void;
  updateMealBox: (box: Box | null) => void;
  hasValidMealCart: boolean;
  hasSubscriptionItems: () => boolean;
  getFirstDelieryDate: () => Date;

  clear: () => void;
  clearMeals: () => void;
  count: number;
  deliveryFee: number;
  totalCoupon: number;
  totalRefund: number;
  subTotal: number;
  total: number;

  setValue: (
    func: (cart: Cart & { version: number }) => Cart & { version: number }
  ) => void;
} => {
  const { user } = useAuth();
  const [value, setValue] = useRecoilState(CartAtom);
  const notifications = useNotifications();
  const locale = useLocale();
  const { mealBoxes } = useMealBoxes();

  const deliveryWindow = useDeliveryWindow();

  const previousDeliveryDate = usePrevious(deliveryWindow?.value?.date);
  const previousCarrierId = usePrevious(deliveryWindow?.value?.carrier?.id);

  const segment = useSegment();
  /*
  useEffect(() => {
    if (deliveryWindow?.value?.date) {
      const onshotItems = value?.oneshot?.items.map((orderItem) => {
        if (orderItem?.item?.type === OrderableItemType.PRODUCT) {
          return orderItem;
        }
        
        if(orderItem?.item?.type === OrderableItemType.PRODUCT) {
          
        }

        return orderItem;
      });
    }
  }, [deliveryWindow?.value?.date]);
*/

  const getOrderType = () => {
    if (
      !user?.subscription &&
      (value.products.items.some(
        (orderItem) => orderItem?.orderType === OrderType.SUBSCRIPTION
      ) ||
        (value.meals.items.length > 0 &&
          value.meals.orderType === OrderType.SUBSCRIPTION))
    ) {
      return OrderType.SUBSCRIPTION;
    }
    return OrderType.ONESHOT;
  };

  const updateMealCartType = (orderType: OrderType) => {
    setValue((v) => ({
      ...v,

      meals: {
        ...value.meals,
        orderType: orderType,
      },
    }));
  };
  const updateMealBox = (box: Box | null) => {
    setValue((v) => ({
      ...v,
      meals: {
        ...value.meals,

        subscription: {
          ...(value.meals?.subscription || {}),
          box: box || undefined,
        },
      },
    }));
  };

  const hasValidMealCart = useMemo(() => {
    const availableMeals = value.meals.items.filter(
      (orderItem) => orderItem.status === OrderItemStatus.AVAILABLE
    );
    return (
      (value.meals.orderType === OrderType.ONESHOT &&
        availableMeals.length > 0) ||
      (value.meals.orderType === OrderType.SUBSCRIPTION &&
        availableMeals.length > 2)
    );
  }, [value.meals.items, value.meals.orderType]);

  const getBoxFromMealSelection = (items: OrderItem[]): Box | undefined => {
    const computed = {
      type: `VEGGIE`,
      portions: 0,
    };

    items
      ?.filter((orderItem) => orderItem?.item?.type === OrderableItemType.MEAL)
      .forEach((orderItem) => {
        const nameSplit =
          (orderItem?.item?.id && orderItem.item.id.split(`-`)) || [];

        const countPortion = parseInt(
          nameSplit[nameSplit.length - 1].replace(`P`, ``)
        );

        let boxType: `VEGGIE` | `ORIGINAL` = `VEGGIE`;
        if (!orderItem?.item?.tags?.includes(OrderableItemTag.VEGGIE)) {
          boxType = `ORIGINAL`;
        }

        if (computed.type !== `ORIGINAL`) {
          computed.type = boxType;
        }

        if (countPortion > computed.portions) {
          computed.portions = countPortion;
        }
      });

    if (computed.portions < 2) {
      return undefined;
    }

    const boxId = `BOX${computed.portions}${
      computed.type === `VEGGIE` ? `2` : `1`
    }`;

    const box = mealBoxes?.find((box) => box.id === boxId);

    return box;
  };

  const addItems = (
    orderItems: OrderItem[],
    orderType: OrderType | `MEAL`,
    notify = false
  ) => {
    // packageBoxFromMeals();

    const items: (OrderItem & {
      orderType?: OrderType;
    })[] =
      orderType === `MEAL`
        ? [
            ...value.meals.items.filter((orderItem) => {
              return !orderItems
                .map((item) => item?.item?.id)
                .includes(orderItem?.item?.id);
            }),
          ]
        : [
            ...value.products.items.filter((orderItem) => {
              return !orderItems
                .map((item) => item?.item?.id)
                .includes(orderItem?.item?.id);
            }),
          ];

    orderItems.forEach((orderItem) => {
      if (!orderItem?.item) {
        return;
      }

      const typeLabel = orderItem?.item?.type
        ? orderItem.item.type.charAt(0).toUpperCase() +
          orderItem.item.type.slice(1)
        : undefined;

      if (typeLabel && orderItem?.qty && orderItem?.qty > 0) {
        segment.track(`OrderableItemAddedToCart`, {
          products: [formatOrderableItem(orderItem.item)],
          currency: `EUR`,
          value: orderItem.item.finalPrice,
        });
      }

      if (orderItem?.qty && orderItem?.qty < 0) {
        segment.track(`OrderableItemRemovedFromCart`, {
          products: [formatOrderableItem(orderItem.item)],
          currency: `EUR`,
          value: orderItem.item.finalPrice,
        });
      }
      const existingItem =
        orderType === `MEAL`
          ? value.meals.items.find((item) => {
              return item?.item?.id === orderItem?.item?.id;
            })
          : value.products.items.find((item) => {
              return (
                item?.item?.id === orderItem?.item?.id &&
                orderType === item.orderType
              );
            });

      const index =
        orderType === `MEAL`
          ? value.meals.items.findIndex((item) => {
              return item?.item?.id === orderItem?.item?.id;
            })
          : value.products.items.findIndex((item) => {
              return (
                item?.item?.id === orderItem?.item?.id &&
                orderType === item.orderType
              );
            });

      if (existingItem) {
        const newQty = (existingItem.qty || 0) + (orderItem.qty || 0);
        const newPrice = (existingItem?.item?.finalPrice || 0) * newQty;

        if (newQty > 0 && index > -1) {
          items.splice(index, 0, {
            ...existingItem,
            qty: newQty,
          });
        }
      } else {
        const { createdAt, status, ...other } = orderItem;
        items.push({
          ...other,
          createdAt: createdAt || new Date(),
          status: status || OrderItemStatus.AVAILABLE,
          orderType: orderType !== `MEAL` ? orderType : undefined,
        });
      }
    });

    let box: Box | undefined = undefined;
    if (orderType === `MEAL`) {
      box = getBoxFromMealSelection(items);
    }

    const sortItems = items.sort((a, b) => {
      if (!a.createdAt || !b.createdAt) {
        return 0;
      }
      if (isAfter(a.createdAt, b.createdAt)) {
        return 1;
      }
      if (isBefore(a.createdAt, b.createdAt)) {
        return -1;
      }
      return 0;
    });

    setValue((v) => ({
      ...v,
      ...(orderType === `MEAL`
        ? {
            meals: {
              ...value.meals,
              items: sortItems as (OrderItem & {
                item: Meal;
              })[],
              subscription: {
                box,
              },
            },
          }
        : {
            products: {
              ...value.products,
              items: (
                sortItems as (OrderItem & {
                  orderType: OrderType;
                })[]
              ).filter((orderItem) => !!orderItem?.item?.id),
            },
          }),
    }));
  };

  const clear = () => {
    setValue((v) => ({
      ...v,
      meals: {
        orderType: OrderType.SUBSCRIPTION,
        items: [],
      },
      products: {
        items: [],
      },
      coupons: [],
      refunds: [],
      subTotal: 0,
      total: 0,
      deliveryFee: 0,
      totalCoupon: 0,
      totalRefund: 0,
    }));
  };

  const clearMeals = () => {
    setValue((v) => ({
      ...v,
      meals: {
        orderType: OrderType.SUBSCRIPTION,
        items: [],
      },
    }));
  };

  const addCoupon = (newCoupon: Coupon) => {
    const couponExist = value?.coupons?.find((coupon: Coupon) => {
      return coupon?.code === newCoupon?.code;
    });

    if (!couponExist) {
      const coupons =
        value.coupons.length > 0 ? [...value.coupons, newCoupon] : [newCoupon];
      setValue((v) => ({
        ...v,
        coupons,
      }));

      if (
        newCoupon.product &&
        value.products.items.findIndex((item) => {
          return item?.item?.id === newCoupon.product?.id;
        }) === -1
      ) {
        addItems(
          [
            {
              item: newCoupon.product,
              qty: 1,
            },
          ],
          OrderType.ONESHOT,
          true
        );
      }
    } else {
      notifications.error(`coupon exist`);
    }
  };

  const removeCoupon = (code: string) => {
    const coupons = value.coupons.filter((coupon) => coupon.code !== code);
    setValue((v) => ({ ...v, coupons }));
  };

  const addRefund = (refund: Refund) => {
    if (refund.orderApplied === undefined) {
      if (value.refunds.length > 0) {
        if (
          value.refunds.findIndex(
            (cartRefund: Refund) => cartRefund.id === refund.id
          ) < 0
        ) {
          const refunds = [...value.refunds, refund];
          setValue((v) => ({ ...v, refunds }));
        } else {
          notifications.error(`refund already in cart`);
        }
      } else {
        const refunds = [refund];
        setValue((v) => ({ ...v, refunds }));
      }
    } else {
      notifications.error(`refund already applied`);
    }
  };

  const removeRefund = (refund: Refund) => {
    const refunds = value.refunds.filter(
      (cartRefund) => cartRefund.id !== refund.id
    );
    setValue((v) => ({ ...v, refunds }));
  };

  const getFirstDelieryDate = () => {
    if (hasValidMealCart) {
      return getNextDeliveryDate({
        type: OrderableItemType.MEAL,
      });
    }

    return getNextDeliveryDate({
      type: OrderableItemType.PRODUCT,
    });
  };

  const hasSubscriptionItems = () => {
    return (
      value?.products?.items?.some(
        (item) => item?.orderType === OrderType.SUBSCRIPTION
      ) ||
      (value?.meals?.orderType === OrderType.SUBSCRIPTION && hasValidMealCart)
    );
  };

  const { count, subTotal, totalCoupon, totalRefund, deliveryFee } =
    useMemo(() => {
      let count = 0;
      let subTotal = 0;
      let totalCoupon = 0;
      let totalRefund = 0;

      const availableFilter = (orderItem: OrderItem) =>
        orderItem.status === OrderItemStatus.AVAILABLE;

      const mealItems = value.meals.items.filter(availableFilter);
      const productItems = value.products.items.filter(availableFilter);

      productItems.forEach((item) => {
        if (item?.item?.finalPrice && item?.qty) {
          subTotal += parseFloat((item.item.finalPrice * item.qty).toFixed(2));
          if (item.item?.deposit) {
            subTotal += parseFloat((item.item.deposit * item.qty).toFixed(2));
          }
          count += item.qty;
        }
      });

      if (
        hasValidMealCart &&
        !(
          value.meals?.orderType === OrderType.SUBSCRIPTION &&
          user?.subscription
        )
      ) {
        mealItems.forEach((orderItem) => {
          subTotal +=
            value.meals?.orderType === OrderType.SUBSCRIPTION
              ? orderItem?.item?.cataloguePrice || 0
              : orderItem?.item?.finalPrice || 0;
          count += 1;
        });
      }

      value.refunds?.forEach((refund) => {
        totalRefund = totalRefund + (refund.amount || 0);
      });

      if (subTotal < totalCoupon) {
        totalCoupon = subTotal;
      }

      const deliveryFee = getDeliveryFee(
        subTotal,
        OrderType.ONESHOT,
        deliveryWindow?.value?.address?.countryIso2 || `BE`,
        deliveryWindow.value.deliveryType!,
        value.coupons
      );

      value.coupons?.forEach((coupon) => {
        totalCoupon =
          totalCoupon +
          getCouponAmount(coupon, subTotal, subTotal + deliveryFee, [
            ...productItems,
            ...mealItems,
          ]);
      });

      return {
        count,
        subTotal,
        totalCoupon,
        totalRefund,
        deliveryFee,
      };
    }, [
      deliveryWindow.value?.address?.countryIso2,
      deliveryWindow.value.deliveryType,
      hasValidMealCart,
      user?.subscription,
      value.coupons,
      value.meals.items,
      value.meals?.orderType,
      value.products.items,
      value.refunds,
    ]);

  const total = subTotal + (deliveryFee || 0) - totalCoupon - totalRefund;

  return {
    ...value,
    getOrderType,
    addItems,
    addCoupon,
    removeCoupon,
    clear,
    clearMeals,
    addRefund,
    removeRefund,
    updateMealCartType,
    updateMealBox,
    hasValidMealCart,
    hasSubscriptionItems,
    getFirstDelieryDate,
    count,
    setValue,
    totalCoupon: Math.floor(totalCoupon * 100) / 100,
    totalRefund: Math.floor(totalRefund * 100) / 100,
    subTotal: Math.floor(subTotal * 100) / 100,
    deliveryFee,
    total: total < 0 ? 0 : Math.floor(total * 100) / 100,
  };
};

export default useCart;
