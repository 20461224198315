"use client";

import React, { useState } from "react";

import NextImage, { ImageProps as NextImageProps } from "next/image";
import { twMerge } from "tailwind-merge";

import DefaultImage from "@/images/default_image.svg";

export type ImageProps = Omit<NextImageProps, `loader`> & {
  effect?: `fade` | `blur`;
  fallbackImage?: any;
};

const Image = (props: ImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const {
    effect = `fade`,
    src,
    alt,
    className,
    fallbackImage,
    ...other
  } = props;

  const FallbackImage = fallbackImage || DefaultImage;

  const handleLoadingComplete = () => {
    setIsLoaded(true);
  };
  return (
    <NextImage
      src={hasError ? FallbackImage : src}
      alt={alt}
      {...other}
      onLoad={handleLoadingComplete}
      className={twMerge(
        `transition-all`,
        effect === `fade` && `opacity-0 duration-200 ease-in`,
        effect === `fade` && isLoaded && `opacity-100`,
        effect === `blur` && `grayscale blur-2xl`,
        effect === `blur` && isLoaded && `grayscale-0 blur-0`,
        className
      )}
      onError={() => {
        setHasError(true);
      }}
      loader={
        hasError
          ? undefined
          : ({ src, width: w, quality }) => {
              const q = quality || 75;
              return `https://cdn.efarmz.be/cdn-cgi/image/quality=${q},f=auto,width=${w}/${src}`;
            }
      }
    />
  );
};
export default Image;
