const ProductActionButtonsSkeleton = () => {
  return (
    <div className="space-y-1 mt-4">
      <div className="rounded transition-all relative py-3 px-8 text-base font-medium bg-choux-500 hover:bg-[#038a74] text-green-50 inline-flex items-center gap-x-1.5 justify-center w-full">
        <div className="h-6 bg-gray-200/10 rounded-full  w-full animate-pulse"></div>
      </div>
    </div>
  );
};

export default ProductActionButtonsSkeleton;
