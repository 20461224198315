import React from "react";
import { HiMinus, HiPlus } from "react-icons/hi2";

import { twMerge } from "tailwind-merge";
type NumericInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  `size`
> & {
  value?: number;
  variant?: `contained` | `default`;
  color?: `primary` | `secondary` | `default`;
  type?: `button` | `submit` | `reset` | undefined;
  size?: `xs` | `sm` | `md` | `lg` | `xl`;
  error?: string;
  className?: string;
  Icon?: any;
  onClickAdd?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickRemove?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const NumericInput = React.forwardRef<HTMLInputElement, NumericInputProps>(
  (
    {
      id,
      name,
      value,
      color = `default`,
      variant = `contained`,
      size = `md`,
      disabled,
      className,
      error,
      Icon,
      onClickAdd,
      onClickRemove,
      ...other
    }: NumericInputProps,
    ref
  ) => {
    const handleAddQty = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (onClickAdd) {
        onClickAdd(e);
      }
    };

    const handleRemoveQty = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (onClickRemove) {
        onClickRemove(e);
      }
    };

    return (
      <div
        className={twMerge(
          `rounded text-xs font-semibold text-slate-900 transition-all inline-flex items-center`,
          variant === `default` && `bg-transparent`,
          color === `primary` &&
            variant === `contained` &&
            `bg-choux-500 text-green-50`,
          color === `secondary` &&
            variant === `contained` &&
            `bg-menthe-300 text-vert-500`,
          color === `default` && variant === `contained` && `bg-gray-100`,
          color === `primary` && variant === `default` && `text-choux-500`,
          color === `secondary` && variant === `default` && `text-vert-500`,

          disabled && `opacity-60`,
          className
        )}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <button
          data-action="decrement"
          className={twMerge(
            `text-white m-2 rounded-md flex items-center justify-center cursor-pointer `,
            color === `primary` && variant === `contained` && `bg-[#038a74]`,
            color === `secondary` && variant === `contained` && `bg-[#d0e0e0]`,
            color === `default` && variant === `contained` && `bg-gray-200`,
            color === `primary` && ` text-green-50`,
            color === `secondary` && `text-vert-500`,
            color === `default` && `text-terreau`,
            size === `xs` && `h-3 w-3`,
            size === `sm` && `h-4 w-4`,
            size === `md` && `h-5 w-5`,
            size === `lg` && `h-6 w-6 `,
            size === `xl` && `h-8 w-8 `
          )}
          onClick={handleRemoveQty}
        >
          <HiMinus className="m-auto text-sm font-bold w-4" />
        </button>

        <div
          className={twMerge(
            `flex grow justify-center items-center`,
            size === `xs` && `py-1 px-2 text-xs h-[22.5px]`,
            size === `sm` && `py-1 px-2.5 text-sm h-[26.25px]`,
            size === `md` && `py-1.5 px-3 text-sm h-[30px]`,
            size === `lg` && `py-2 px-4 text-sm h-[37.5px]`,
            size === `xl` && `py-3 px-8 text-base font-medium h-[45px]`
          )}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {Icon && (
            <Icon
              className={twMerge(`font-bold mr-2`, size === `xs` && `w-3 h-3`)}
            />
          )}
          <span
            className={twMerge(
              `text-sm inline-block x-0 py-0 bg-transparent outline-none focus:outline-none text-center font-semibold text-md md:text-basecursor-default border-0`,
              size === `xs` && `text-xs`,
              size === `sm` && `text-sm`,
              size === `md` && `text-sm`,
              size === `lg` && `text-sm`,
              size === `xl` && `text-base`,
              color === `primary` && ` text-green-50`,
              color === `secondary` && `text-vert-500`,
              color === `default` && `text-terreau`
            )}
          >
            {value}
          </span>
        </div>

        <button
          data-action="increment"
          className={twMerge(
            `text-white m-2 rounded-md flex items-center justify-center cursor-pointer `,
            color === `primary` && variant === `contained` && `bg-[#038a74]`,
            color === `secondary` && variant === `contained` && `bg-[#d0e0e0]`,
            color === `default` && variant === `contained` && `bg-gray-200`,
            color === `primary` && ` text-green-50`,
            color === `secondary` && `text-vert-500`,
            color === `default` && `text-terreau`,
            size === `xs` && `h-3 w-3`,
            size === `sm` && `h-4 w-4`,
            size === `md` && `h-5 w-5`,
            size === `lg` && `h-6 w-6 `,
            size === `xl` && `h-8 w-8 `
          )}
          onClick={handleAddQty}
        >
          <HiPlus className="stroke-4 font-bold" />
        </button>
      </div>
    );
  }
);

NumericInput.displayName = `NumericInput`;

export default NumericInput;
