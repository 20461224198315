import useSWR from "swr";

import { Box } from "@efarmz/efarmz-domain-typescript";

import SWRFetcher from "@/utils/SWRFetcher";

const useMealBoxes = (): {
  mealBoxes: Box[];
} => {
  const { data } = useSWR(
    {
      url: `${process.env.NEXT_PUBLIC_EFARMZ_URL}/api/boxes`,
    },
    SWRFetcher
  );

  return {
    mealBoxes: data?.data || [],
  };
};

export default useMealBoxes;
